import React from 'react'
import PropTypes from 'prop-types'
import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import Avatar from '../Avatar'
import useListenerClose from '../../hooks/useListenerClose'
const UserPopover = ({ onLogoutUser, onToggle, name, email }) => {
  const { ref } = useListenerClose(onToggle)
  return (
    <>
    <div className='tw-bg-black tw-fixed tw-bg-opacity-10 tw-h-screen tw-w-screen tw-left-0 tw-top-0 tw-z-10' />
    <div ref={ref} className="user-popover tw-absolute tw-right-0 tw-top-0 tw-z-50">
        <div className="tw-flex tw-p-4 tw-flex-col tw-justify-between tw-w-60 tw-bg-white">
          <div className='avatar-name tw-pb-4'>
            <Avatar name={name} email={email} color='black'/>
          </div>
          <div
            onClick={onLogoutUser}
            className="tw-group link-logout-user tw-text-black tw-flex tw-flex-row tw-justify-between tw-cursor-pointer tw-text-lilaVF tw-items-center"
          >
            <Paragraphs
              size="xs"
              weight="bold"
              className="group-hover:tw-underline"
            >
              Cerrar sesión
            </Paragraphs>
            <span className="ic-menu-dots material-icons tw-block tw-flex tw-items-center tw-justify-center">
              logout
            </span>
          </div>
      </div>
    </div>
    </>
  )
}

UserPopover.propTypes = {
  notificacions: PropTypes.number,
  onToggle: PropTypes.func,
  onLogoutUser: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string
}

UserPopover.defaultProps = {
  notificacions: 3,
  onToggle: () => console.log('closing')
}

export default UserPopover
