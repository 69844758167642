import styled from 'styled-components'

export const LayoutContainer = styled.div`
  margin-left: 50px;
  padding-top: 126px;

  & .Sidebar-small img {
    width: 18px;
    height: 18px;
  }
`
