import React, { useEffect, useState } from 'react'
import {
  Button,
  Hero,
  Paragraphs,
  TextInput
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import Logo from '../../../../components/Logo'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { forms } from '../../constants/forms'
import useLogin from '../../hooks/useLogin'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
// import Themes from '../../components/themes/index'

const LoginScreen = () => {
  const { t } = useTranslation()
  const cookies = new Cookies()
  const [currentCookie, setCurrentCookie] = useState(cookies.get('avoid_sso_1'))

  /// custom hooks
  const { onLogin, loading } = useLogin()
  const history = useHistory()
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()

  // redux
  const { colors, loading: loadingColors } = useSelector(
    (state) => state.colorsReducer
  )

  /* A useMemo hook that is checking if the cookie is set, if not it is setting it. */
  useEffect(() => {
    const redirect = () => {
      setTimeout(() => {
        location.href = window.location.href
        // window.location.href = 'https://accounts-dev.farmacloud.io/sso/bi-prod?login=referrer' // farmacloud DEV
        window.location.href = 'https://accounts.farmacloud.io/sso/bi-prod?login=referrer' // farmacloud PROD
      }, 120)
    }
    setTimeout(() => {
      setCurrentCookie('true')
      cookies.remove('avoid_sso_1', { path: '/' })
    }, 500)

    if (currentCookie === 'true') {
      return
    }
    cookies.set('avoid_sso_1', true, { path: '/' })
    redirect()
  }, [])

  /**
   * form submit for login form
   *
   *
   * @author   ehernandez
   * @param    {Object} data element
   * @param    {String}   data.username
   * @param    {String}   data.password
   */
  const onSubmit = async (values) => {
    const { success, errors } = await onLogin(values)
    if (!success) {
      return {
        success,
        errors
      }
    }
    history.push('/')
  }

  if (loadingColors) {
    return null
  }

  return (
    <div className="login tw-flex tw-flex-col tw-h-screen">

      <Hero />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="tw-flex tw-flex-col tw-items-center tw-flex-1 tw-h-full"
      >
        <div
          className="tw-p-6 tw-flex tw-items-center tw-justify-center "
          style={{ height: 140 }}
        >
          <Logo logo={colors?.logo_lg} name={colors?.nombre} />
        </div>

        <div className="tw-p-6">
          <div className="tw-p-4">
            <Paragraphs weight="bold" size="xl">
              {t('login.welcome', { name: colors?.nombre })}
            </Paragraphs>
          </div>
          <div>
            {forms.login.map((i) => (
              <TextInput
              type={i.type}
                key={i.id}
                label={i.label}
                register={register(i.name, {
                  required: {
                    value: true,
                    message: 'Campo requerido'
                  }
                })}
                error={errors?.usuario?.message}
              />
            ))}
          </div>
        </div>
        <Button
          disabled={loading}
          label={t('actions.Entrar')}
          uppercase
          type="submit"
        />
      </form>
    </div>
  )
}

export default LoginScreen
