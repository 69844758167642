import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RECURSOS } from '../../constants/api'
import useMutation from '../useMutation'

export const useMenu = () => {
  const [data, setData] = useState(null)
  const [getMenus] = useMutation(RECURSOS)
  const { user } = useSelector(state => state.userReducer)
  /**
   * get Menu items
   *
   *
   * @author   ehernandez
   */

  const getMenuList = async () => {
    const { success, data: menu } = await getMenus({ method: 'get' })
    let newData = menu
    if (success) {
      // const isMobile = window.innerWidth < 640
      // if (isMobile) {
      //   const only = [1600, 1601]

      //   const newMenu = menu.filter(item => {
      //     if (only.includes(item.id)) {
      //       item.children = item.children.filter(child => {
      //         if (only.includes(child.id)) {
      //           return child
      //         }
      //         return null
      //       })
      //       return item
      //     }
      //     return null
      //   })
      //   newData = newMenu
      // }
      if (user?.username?.includes('_AUX')) {
        const only = [1609, 1612, 1613, 1615, 1601, 1610, 1611]

        const newMenu = menu.filter(item => {
          if (only.includes(item.id)) {
            item.children = item.children.filter(child => {
              if (only.includes(child.id)) {
                return child
              }
              return null
            })
            return item
          }
          return null
        })
        newData = newMenu
      }

      // order array object for key order
      newData = newData.sort((a, b) => {
        if (a.order > b.order) {
          return 1
        }
        if (a.order < b.order) {
          return -1
        }
        return 0
      }
      )
      newData = newData.map(i => {
        i.children.sort(function (a, b) {
          if (a.orden > b.orden) {
            return 1
          }
          if (a.orden < b.orden) {
            return -1
          }
          return 0
        })
        return i
      })
      setData(newData)
    }
  }
  /**
   * initialice funtion getmenus
   *
   *
   * @author   ehernandez
   * @param   {Object} item
   */

  useEffect(() => {
    if (user) {
      getMenuList()
    }
  }, [user])

  return { data }
}
