import { Paragraphs } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import React from 'react'
import PropTypes from 'prop-types'
import { ContainerAvatar } from './styles'
// import UserPopover from '../UserPopover/UserPopover'
/**
 * @author ehernandez
 * @returns {React.Component}
 */
const Avatar = ({ name, email, onClick, color }) => {
  const getInitials = () => {
    if (name?.length > 0) {
      if (name.length > 1) {
        return name[0] + name[1]
      }
      return name[0]
    }

    return ''
  }

  return (
    <div className='avatar tw-cursor-pointer tw-relative' style={{ maxWidth: 180 }}>
      <div className='tw-flex tw-items-center' onClick={() => onClick()}>
      <ContainerAvatar className='tw-relative tw-bg-primary tw-text-white  tw-rounded-full tw-flex tw-items-center tw-justify-center tw-borde-2 tw-border-white tw-border-solid'>
        <Paragraphs size='xs' uppercase weight='bold'>{getInitials()}</Paragraphs>
        </ContainerAvatar>
        <div className={`tw-pl-3 tw-text-${color}`} >
          <Paragraphs size='xs' weight='bold'>{name}</Paragraphs>
          <Paragraphs size='xxs'>{email}</Paragraphs>
        </div>
      </div>
    </div>
  )
}

Avatar.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  onLogoutUser: PropTypes.func,
  widthContext: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string
}

Avatar.defaultProps = {
  color: 'white'
}

export default Avatar
