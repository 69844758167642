import { combineReducers } from 'redux'
import userReducer from './user'
import colorsReducer from './colors'
import corporateReducer from './corporate'
import navigationReducer from './navigation'

export default combineReducers({
  userReducer,
  colorsReducer,
  corporateReducer,
  navigationReducer
})
