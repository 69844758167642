import React, { useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import ThemeXf from './themes/ThemeXf/ThemeXf'
import ThemeDefault from './themes/ThemeDefault/ThemeDefault'
import useMenu from '../../hooks/useMenu'
import useNavigation from '../StackNavigator/hooks/useNavigation'
import { useUser } from '../../hooks/useUser/useUser'

const Layout = ({ children }) => {
  const navigation = useNavigation()
  const { handleUser } = useUser()

  const { data } = useMenu()

  /**
   * open software on new chrome tab
   *
   *
   * @author   ehernandez
   * @param   {Object} app
   * @param   {Object}  app.url
   * @param   {Object}  app.comprado
   */

  const openSotware = (app) => {
    if (app?.comprada) {
      window.open(app.url + '/' + app.token)
    }
  }

  /**
   * click on menu item
   *
   *
   * @author   ehernandez
   * @param   {Object} item
   */

  const handleClickOnMenu = (item) => {
    navigation.push(item)
  }

  /**
* Open initial route
* @author   ehernandez
* @param   {Array} menus
* @param   {Array} menus.children

*/
  const getFirstItemMenu = (menus) => {
    if (menus.length > 0) {
      const [first] = menus[0].children
      navigation.push(first)
    }
  }

  useLayoutEffect(() => {
    handleUser()
  }, [])

  /**
   * Open initial route
   * @author   ehernandez
   */
  useEffect(() => {
    if (navigation?.data?.length === 0 && data) {
      getFirstItemMenu(data)
    }
  }, [navigation?.data, data])
  const { theme } = useSelector((state) => state.colorsReducer)
  if (theme === 'xarxafarma') {
    return (
      <ThemeXf
        onHandleClickOnSoftware={openSotware}
        onHandleClickOnMenu={handleClickOnMenu}
      >
        {children}
      </ThemeXf>
    )
  }
  return (
    <ThemeDefault
      onHandleClickOnSoftware={openSotware}
      onHandleClickOnMenu={handleClickOnMenu}
    >
      {children}
    </ThemeDefault>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Layout
