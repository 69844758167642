import { NAVIGATION_PUSH, NAVIGATION_PUSH_FAILED, NAVIGATION_PUSH_SUCCESS, NAVIGATION_TOGGLE } from '../constants/navigation'

const initialState = {
  error: false,
  loading: false,
  data: []
}
const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NAVIGATION_PUSH:
      return {
        ...state,
        error: false,
        loading: true
      }

    case NAVIGATION_PUSH_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: action.stack

      }

    case NAVIGATION_PUSH_FAILED:
      return {
        ...state,
        error: true,
        loading: false
      }

    case NAVIGATION_TOGGLE:
      return {
        ...state,
        error: false,
        loading: false,
        data: action.stack
      }

    default:
      return state
  }
}

export default navigationReducer
