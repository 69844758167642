import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LayoutContainer } from './styles'
import {
  Sidebar,
  NavTab,
  Tab,
  Paragraphs
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import useNavigation from '../../../StackNavigator/hooks/useNavigation'
import { useUser } from '../../../../hooks/useUser/useUser'
import useMenu from '../../../../hooks/useMenu'
import Avatar from '../../../Avatar/Avatar'
import { useSelector } from 'react-redux'
import UserPopover from '../../../UserPopover/UserPopover'
import useWindowSize from '../../../../hooks/useWindowSize'

const ThemeDefault = ({
  children,
  onHandleClickOnSoftware,
  onHandleClickOnMenu
}) => {
  const { data } = useMenu()
  const navigation = useNavigation()
  const { logoutUser } = useUser()
  const { user } = useSelector(state => state.userReducer)
  const [showContextMenu, setShowContextMenu] = useState(false)
  const windowsSize = useWindowSize()

  const [openMenuMobile, setOpenmenuMobile] = useState(false)

  return (
    <LayoutContainer width={windowsSize?.width} className="layout theme-default tw-h-screen tw-overflow-hidden tw-relative tw-flex tw-flex-col tw-bg-gray-50">
      {
        showContextMenu && (<UserPopover
          onLogoutUser={logoutUser}
          email={user?.email} name={user?.name} onToggle={() => setShowContextMenu(false)} />)
      }
      <div className='sm:tw-block tw-hidden'>
      <Sidebar
        onSelectSoftware={onHandleClickOnSoftware}
        position={'fixed'}
        data={data}
        onClickItemMenu={onHandleClickOnMenu}
      />
      </div>

{openMenuMobile && <div className='menu-mobile tw-fixed tw-shadow tw-w-10/12 tw-h-screen tw-bg-primary tw-top-0 tw-z-50'>
        <div className='tw-text-right tw-relative ' style={{ height: 40 }} >
          <div className='tw-absolute tw-right-0 tw-p-4 tw-cursor-pointer' onClick={() => setOpenmenuMobile(false)}>
        <span
                className="material-icons tw-text-white tw-pr-2 tw-flex"
              >
                close
              </span>
              </div>
        </div>
        {
          data.map(i => <div className='' key={i.id}>
            <Paragraphs className={'tw-text-white tw-py-2 tw-pl-3'} weight='bold' size='lg'>
            {i.nombre}
            </Paragraphs>
            {
              i.children.map(e => <div onClick={() => {
                onHandleClickOnMenu(e)
                setOpenmenuMobile(false)
              }} key={e.id} className='tw-cursor-pointer'>
              <Paragraphs size='md' className='tw-py-1 tw-text-white tw-pl-8'>{e.nombre}</Paragraphs>
            </div>)
            }

          </div>)
        }
      </div>}
      <div className="tw-absolute tw-w-full tw-top-0">
         <div className='header tw-bg-tertiary tw-flex tw-justify-between tw-items-center tw-hidden' id={'bi-topbar'} style={{ height: 48 }}>
        {/* <div className='header tw-bg-tertiary tw-flex tw-justify-between tw-items-center' id={'bi-topbar'} style={{ height: 50 }}> */}
            <div className='tw-px-4 tw-flex tw-items-center'>
              <div className='tw-hidden'>
              <span
              onClick={() => setOpenmenuMobile(true)}
                className="tw-hidden material-icons tw-text-white tw-pr-2 tw-cursor-pointer"
              >
                menu
              </span>
              </div>
              <img src={require('../../../../assets/img/logo.svg').default} alt='logo' style={{ width: 80 }} />
            </div>
            <div className='tw-px-4'>
              <Avatar email={user?.email} name={user?.name} onClick={() => setShowContextMenu(!showContextMenu)} />
            </div>
        </div>
        {/* <CustomHeaderLayout
          linkLogo="https://particulars.xarxafarma.com/pharmacy/home"
          // logo={colors?.logo_sm || logo}
          user={user}
          onCloseSesion={() => logoutUser()}
        >
        </CustomHeaderLayout> */}
        <div className='tw-hidden sm:tw-block'>
        <NavTab>
          {navigation.data.map((tab, idx) => (
            <Tab
              key={idx}
              name={tab.nombre}
              active={tab.active}
              onPress={() => navigation.toggle(tab)}
              onClose={() => navigation.close(tab)}
            />
          ))}
        </NavTab>
        </div>
      </div>
      <div className="tw-h-full tw-w-full">{children}</div>
    </LayoutContainer>
  )
}

ThemeDefault.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onHandleClickOnSoftware: PropTypes.func,
  onHandleClickOnMenu: PropTypes.func
}

export default ThemeDefault
