import { LOGOUT_USER, SET_TOKEN_USER, UPDATE_USER } from '../constants/user'

const initialState = {
  token: null,
  user: null
}
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN_USER:
      return {
        ...state,
        token: action.data.token,
        refresh_token: action.data.refresh_token,
        user: action.data.user
      }
    case UPDATE_USER:
      return {
        ...state,
        user: action.data
      }

    case LOGOUT_USER:
      return {
        ...state,
        token: null,
        user: null
      }
    default:
      return state
  }
}

export default userReducer
