import { useEffect, useState } from 'react'
import { BI_MENU } from '../../../../infrastructure/constants/api'
import useMutation from '../../../../infrastructure/hooks/useMutation'

export const usePorwerBI = (id) => {
  const [getMenuBi] = useMutation(BI_MENU + id)
  const [BI, setBI] = useState(null)
  const [loading, setLoading] = useState(false)
  /**
   * get token and data from microsoft
   *
   *
   * @author   ehernandez
   * @param   {Object} item
   */

  const getToken = async () => {
    setLoading(true)
    const { success, data } = await getMenuBi({ method: 'get' })
    const response = data
    setLoading(false)
    if (success) {
      // si es la home, reemplazamos la respuesta en modo mobil -> TODO: DELETE THIS COMMENTED BLOCK
      // if (id === 1601 && window.innerWidth < 640) {
      //   response.reportSectionBI.pageName = 'ReportSectionacc42f55b461a86c9b65'
      // }
      setBI(response)
    }

    return null
  }

  useEffect(() => {
    getToken()
  }, [])

  return { getToken, BI, loading }
}
