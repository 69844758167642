export const USUER = '/usuario'
export const LOGOUT = '/logout'

export const CORPATIVE_INFO = '/grupo-corporativo'

export const APPS = '/aplicaciones'
export const INFO_CORPORATE = '/organizacion'

export const RECURSOS = '/recursos'

export const BI_MENU = '/recursos/bi/'
