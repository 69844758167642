import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useComporativeColor } from '../../hooks/useCorporativeColor/useComporativeColor'
import { GlobalStyle } from '../../routes/styles'
import { actionSetTheme } from '../../redux/actions/colors'
import { useQuery } from '../../hooks/useQuery/useQuery'
import Cookies from 'universal-cookie'

const ThemeProvider = ({ children }) => {
  const { colors, theme } = useSelector((state) => state.colorsReducer)
  const dispatch = useDispatch()
  const cookies = new Cookies()
  const { getCorporativeInfo } = useComporativeColor()
  const query = useQuery()
  /**
   * Confirm url xarxafarma for custom theme
   *
   * @author   ehernandez
   */
  useLayoutEffect(() => {
    let thm = query.get('theme') || theme
    // thm = 'xarxafarma'
    thm = thm || 'fedefarma'
    if (thm === 'xarxafarma') {
      const favicon = document.getElementById('favicon')
      favicon.href = 'favicon.ico'
    }
    if (cookies.get('avoid_sso_1')) {
      getCorporativeInfo(thm)
      dispatch(actionSetTheme(thm))
    }
  }, [])

  return (
    <>
      {colors && <GlobalStyle paleta={colors} />}
      {children}
    </>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default ThemeProvider
