import React from 'react'
import PropTypes from 'prop-types'
import useNavigation from './hooks/useNavigation'
import BisualFarmaScreen from '../../../modules/BI/pages/BisualFarmaScreen'

const StackNavigator = () => {
  const navigation = useNavigation()

  /**
* Function for push tabs in stack
* @author   ehernandez
* @param    {Object} componente   name component for open. This parameter will come from new topic points, which are added within our redux stack
*/
  const getComponent = (componente, key, id) => {
    switch (componente) {
      case 'bi_iframe':
        return <BisualFarmaScreen navigation={navigation} id={id} key={key} />

      default:
        return null
    }
  }

  return navigation?.data?.map((page, idx) => <div
    data-id="stack-navigator"
    key={idx}
    className="stack-navigator tw-w-full tw-h-full"
    style={{ display: page.active ? 'block' : 'none', visibility: page.active ? 'visible' : 'hidden' }}
  >
    {getComponent(page.componente, page.key, page.id)}
  </div>
  )
}

StackNavigator.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default StackNavigator
