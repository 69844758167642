import React, { useState, useEffect } from 'react'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import usePorwerBI from '../../hooks/usePowerBi'
import PropTypes from 'prop-types'
import { Page } from '../../../../components/Page'
import { Spinner } from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { useUser } from '../../../../infrastructure/hooks/useUser/useUser'
import useWindowSize from '../../../../infrastructure/hooks/useWindowSize'
import TopBarManagement from '../../../../components/TopBarManagement'
import Cookies from 'universal-cookie'

const BisualFarmaScreen = ({ navigation, id }) => {
  const { BI, loading } = usePorwerBI(id)
  const [filters, setFilters] = useState([])
  const [codigo, setCodigo] = useState(null)

  const windowSize = useWindowSize()
  /**
   * if BI has content, prepare filters bi
   *
   *
   * @author   ehernandez
   */
  const cookies = new Cookies()
  useEffect(() => {
    const cookie = cookies.get('avoid_sso_1')
    if (cookie) cookies.remove('avoid_sso_1', { path: '/' })
  }, [])

  useEffect(() => {
    if (BI) {
      const data = []
      const principal = BI.filtrosBI.find((i) => i.page === '*')
      setCodigo(principal.value)

      BI.filtrosBI.forEach((i) => {
        const target = {
          table: i.table,
          column: i.column
        }
        data.push({
          $schema: 'http://powerbi.com/product/schema#basic',
          target,
          operator: 'In',
          values: [i.value],
          displaySettings: {
            isLockedInViewMode: true,
            displayName: 'Number of stores'
          }
        })
      })
      setFilters(data)
    }
    return () => {
      setFilters({})
      setCodigo(null)
    }
  }, [BI])

  const setAllHome = async (page) => {
    let pageFilters = await page.getFilters()
    console.log('page filters', pageFilters)
    await page.updateFilters(models.FiltersOperations.ReplaceAll, filters)
    pageFilters = await page.getFilters()
    console.log('page filters APLIED', pageFilters)
  }

  const { handleUser, token } = useUser()

  /**
   * Update data user
   * when user change route
   * @author   ehernandez
   */
  useEffect(() => {
    if (token && window.location.pathname === '/') {
      handleUser()
    }
  }, [])

  return (
    <Page.Container name="PowerBI" className="tw-w-full">
      <Page.Body name="body-powerBi">
         <TopBarManagement />
        {loading && (
          <div className="tw-flex tw-w-full tw-h-full tw-items-center tw-justify-center">
            <Spinner color={'#333'} />
          </div>
        )}
        {BI && filters.length > 0 && (
          <PowerBIEmbed
            eventHandlers={
              new Map([
                [
                  'pageChanged',
                  function (event) {
                    setAllHome(event.detail.newPage)
                  }
                ]
              ])
            }
            cssClassName="tw-h-full"
            embedConfig={{
              viewMode: models.ViewMode.Read,
              permissions: models.Permissions.Read,
              type: 'report', // Supported types: report, dashboard, tile, visual and qna
              id: BI.conexionBI.result.reportId,
              embedUrl: BI.conexionBI.result.embed.embedUrl,
              accessToken: BI.conexionBI.result.embedToken, // Keep as empty string, null or undefined
              tokenType: models.TokenType.Embed,
              pageName: BI.reportSectionBI.pageName,
              filters: [
                {
                  $schema: 'http://powerbi.com/product/schema#basic',
                  displaySettings: {
                    isLockedInViewMode: true,
                    displayName: 'Number of stores'
                  },
                  operator: 'In',
                  target: {
                    table: 'dw_aux_cluster_farmacia',
                    column: 'store_code'
                  },
                  values: [codigo]
                }
              ],
              settings: {
                filterPaneEnabled: false,
                layoutType: windowSize?.width < 768 ? models.LayoutType.MobilePortrait : models.LayoutType.Desktop,
                panes: {
                  filters: {
                    filterPaneEnabled: false,
                    expanded: false,
                    visible: false
                  },
                  pageNavigation: {
                    visible: false
                  }
                },
                visualSettings: {
                  visualHeaders: [
                    {
                      settings: {
                        visible: true
                      }
                    }
                  ]
                }
              }
            }}
          />
        )}
      </Page.Body>
    </Page.Container>
  )
}

BisualFarmaScreen.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  navigation: PropTypes.object
}

export default BisualFarmaScreen
